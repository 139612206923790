import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  carouselRoot: {
    position: 'relative',
    '& .slick-disabled': {
      opacity: '.25'
    },
    '& .slick-next, .slick-prev': {
      zIndex: 1,
    }
  },
  arrow: {
    '& svg': {
      position: 'relative',
      width: 50,
      height: 50,
      color: '#000',
      top: -55,
      borderRadius: '50%',
      background: '#ffffff75',
      boxShadow: '0 1px 2px rgba(0, 0, 0, .2)',
      color: '#414042',
      background: '#ffff',
      // boxShadow: '3px 3px 6px #00000029',
      padding: 5
    }
  },
  arrow1: {
    '& svg': {
      left: -10
    }
  },
  arrow2: {
    '& svg': {
      right: -10
    }
  }
}));
