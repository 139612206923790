import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Container } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LazyLoad from 'react-lazyload';
import Section from '../Section';
import Carousel from '../Carousel';


const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        padding: '0px 125px',
        maxWidth: '100%',
        '@media only screen and (max-width: 425px)': {
            padding: '32px 0px'
        }
    },
    clientFeedback: {
        paddingTop: 100,
        paddingBottom: 40,
        overflow: 'hidden',
        // backgroundColor: '#fff',
        // backgroundColor: '#FBFBFD',
        backgroundColor: '#F9FBFD',
        '& ::before': {
            flex: 0,
            padding: 0
        },
        '@media only screen and (max-width: 425px)': {
            paddingTop: 1,
            paddingBottom: 70
        }
    },
    title: {
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '50px',
        marginBottom: 30,
        textAlign: 'center',
        color: '#414042',
        fontFamily: 'Open Sans, sans-serif',
        '@media only screen and (max-width: 960px)': {
            fontSize: 35,
            lineHeight: '45px'
        },
        '@media only screen and (max-width: 425px)': {
            fontSize: 26,
            lineHeight: '36px',
            marginBottom: 10
        },
        '@media only screen and (max-width: 320px)': {
            fontSize: 24,
            lineHeight: '34px'
            // marginBottom: 40,
        }
    },
    textSlogan: {
        marginTop: 20,
        fontWeight: 400,
        fontFamily: 'Open Sans, sans-serif',
        color: '#414042',
        fontSize: 18,
        lineHeight: '28px',
        textAlign: 'center',
        marginBottom: 40
        // '@media only screen and (max-width: 1024px)': {
        //   marginTop: 10
        // },
        // '@media only screen and (max-width: 425px)': {
        //   fontSize: 16,
        //   padding: '0 1rem'
        // }
    },
    avatar: {
        // marginTop: 89,
        width: 104,
        height: 104,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media only screen and (max-width: 768px)': {
            marginTop: 20
        },
    },
    name: {
        marginTop: 24,
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 18,
        fontWeight: '700',
        lineHeight: '24px',        
        textAlign: 'center',
        marginBottom: '5px',
        '& a': {
            color: '#414042',
        }
    },
    job: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '24px',
        color: '#414042',
        fontWeight: '500',
        // width: '270px',
        margin: '0 auto',
        textAlign: 'center',
        paddingBottom: 20
    },
    opinion: {
        marginTop: 24,
        fontSize: 16,
        lineHeight: '28px',
        color: '#414042',
        fontWeight: '400',
        fontFamily: 'Open Sans, sans-serif',
        maxWidth: 1024,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media only screen and (max-width: 425px)': {
            marginTop: 20
        },
        textAlign: 'center',
    },
    paper: {
        position: 'relative',
        padding: '40px',
        // width: '350px',
        textAlign: 'center',
        borderRadius: 12,
        minHeight: 430,
        // boxShadow: '0 50px 80px 0 rgb(0 11 40 / 8%)',
        height: '100%',
        // '@media only screen and (max-width: 425px)': {
        //     width: '310px',
        //     padding: '25px'
        // },
        // '@media only screen and (max-width: 320px)': {
        //     width: '280px'
        // }
    },
    carousel: {
        '& .arrow button': {
            borderRadius: '50%',
            padding: 12,
            minWidth: 'unset',
            boxShadow: '2px 2px 5px 0px #999999'
        },
        '& .arrow:first-of-type': {
            left: -25
        },
        '& .arrow:last-of-type': {
            right: -25
        },
        '& .slick-track': {
            marginLeft: 'unset'
        },
        '& .slick-next:before': {
            opacity: 0
        },
        '& .slick-prev:before': {
            opacity: 0
        },
        '& .slick-dots': {
            bottom: 25,
            '& li': {
                background: '#E9E9E9',
                borderRadius: '50%',
                width: 15,
                height: 15,
                '&.slick-active': {
                    background: '#9E9E9E',
                }
            }
        },
        '& .slick-arrow': {
            display: 'none'
        }
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    quote: {
        position: 'absolute',
        left: 40,
        '& svg': {
            width: 50,
            height: 50
        }
    }
}));

const ClientFeedbackVG = () => {
    const classes = useStyles();
    const [showCarousel, setShowCarousel] = React.useState(false);

    const woxians = [
      {
        avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1630902470/videoLandingPage/1591126943969.jpg',
        name: 'Simon Yule',
        job: 'Director of Advertising Operations at Umbrella Digital',
        opinion: "Every marketer knows how important content is, but content creation can be a headache for many. That's why I'm excited about the potential of this new video maker tool by WOXO that can easily scale 100%-customized & dynamic videos at speed. We need more innovations like this!",
        link: 'https://www.linkedin.com/in/simonyule/'
      },
      {
        avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1630903815/videoLandingPage/1539248841122.jpg',
        name: 'Marisniulkis Lescaille',
        job: 'Senior Software Engineer at Netflix',
        opinion: "WOXO holds a great potential advantage for innovative technology companies to maximize content creation, customization and personalized recommendations.",
        link: 'https://www.linkedin.com/in/mlescaille/'
      },
      {
        avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1630903875/videoLandingPage/1516470091913.jpg',
        name: 'Dieter Ordoñez',
        job: 'Paid Media Lead at Intuit',
        opinion: "WOXO is a great tool to create content quickly! It’s also ideal for email marketing and it even offers personalized landing pages.",
        link: 'https://www.linkedin.com/in/dieter-ordonez/'
      },
      {
        avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1630904091/videoLandingPage/1517582109303.jpg',
        name: 'Issac Jr.',
        job: 'Senior Data Scientist at Chewy',
        opinion: "It’s interesting to see all the innovative uses that come from using spreadsheets. WOXO is the perfect platform to create assets for personalization tests quickly.",
        link: 'https://www.linkedin.com/in/isaac-jr-rodr%C3%ADguez-b873a2b2/'
      },
      {
        avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1615570093/videoLandingPage/100899531_250149776204343_2314871958182821888_n.jpg.jpg',
        name: 'Cristina',
        job: 'Influencer & Graphic Designer',
        opinion: 'Without the need to be a programmer you can add the Instagram Widget to your website, create different customizations, change colors and CTA text.',
        link: 'https://www.instagram.com/apynapublicidad/'
    },                         
        {
            avatar: 'https://woxo.tech/blog/wp-content/uploads/2021/06/1617325783545-1.jpeg',
            name: 'Julio Jimenez',
            job: 'UI/UX Designer at Adaved',
            opinion: 'I’ve known WOXO for a while now, the video editor is great and easy to use. I can create backup content quickly, which is also 100% customizable. It’s definitely a time saver!',
            link: 'https://www.tiktok.com/@jcjimenezglez'
        },
        {
            avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1601668297/landingPage/0.jpg',
            name: 'Luis Terrero',
            job: 'Co-Founder & Team Lead at PixelPerfecto',
            opinion: 'Video creation is easy with WOXO. It has enabled me to generate a set of professional looking videos in a matter of minutes and suggest thoughtful content that fulfilled my client’s needs.',
            link: 'https://www.linkedin.com/in/latr88/'
        },

        {
            avatar: 'https://res.cloudinary.com/dakp804eh/image/upload/v1615570064/videoLandingPage/149882109_749237876026872_5523946070599541278_n.jpg.jpg',
            name: 'Rodrigo',
            job: 'Instagram Coach and Entrepreneur',
            opinion: 'WOXO is the ideal platform to bring the concept of social proof to your website. They help you add value to your website visitors.',
            link: 'https://www.instagram.com/redaccionescreativas/'
        },
    ];

    React.useEffect(() => {
        setTimeout(() => {
            setShowCarousel(true);
        }, 100);
    }, []);
    return (
        <Section className={classes.clientFeedback}>
            <Container style={{ maxWidth: 1024 }}>
                <Typography variant="h2" className={classes.title}>
                    What our Woxians are saying
                </Typography>
                {/* <Box className={classes.textSlogan}>
                    <p>
                        We celebrate every time your video content levels up your marketing game. Your win is our win! 🎉
                    </p>
                </Box> */}
            </Container>
            <Container style={{ maxWidth: 800 }}>
            <LazyLoad height={300} offset={100}>
                {showCarousel && (
                    <Carousel
                        className={classes.carousel}
                        dots={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        autoplay={true}
                        autoplaySpeed={9000}
                        cssEase={"linear"}
                        adaptiveHeight={true}
                        lazyLoad={true}
                    >
                        {woxians.map((d, i) => {
                            return (
                                <Paper elevation={0} className={classes.paper}>
                                    <Box className={classes.cardContainer}>
                                        <Typography className={classes.quote}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="79.267" height="56.62" viewBox="0 0 79.267 56.62">
                                                <g id="quote" transform="translate(79.267 99.287) rotate(180)">
                                                    <g id="Group_2198" data-name="Group 2198" transform="translate(0 42.667)">
                                                        <g id="Group_2197" data-name="Group 2197">
                                                            <path id="Path_7142" data-name="Path 7142" d="M0,76.639H16.986L5.662,99.286H22.648L33.972,76.639V42.667H0Z" transform="translate(0 -42.667)" fill="#f2f2f2" />
                                                            <path id="Path_7143" data-name="Path 7143" d="M170.667,42.667V76.639h16.986L176.329,99.286h16.986l11.324-22.648V42.667Z" transform="translate(-125.371 -42.667)" fill="#f2f2f2" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </Typography>
                                        <Avatar
                                            alt={d.name}
                                            src={d.avatar}
                                            className={classes.avatar}
                                        />
                                        <Typography className={classes.opinion}>
                                            <q>
                                                {d.opinion}
                                            </q>
                                        </Typography>

                                        <Typography className={classes.name}>
                                            <a href={d.link} target="_blank">
                                                {d.name}
                                            </a>
                                        </Typography>

                                        <Typography className={classes.job}>{d.job}</Typography>
                                    </Box>
                                </Paper>
                            );
                        })}
                    </Carousel>
                )}
            </LazyLoad>
            </Container>
        </Section >
    );
};

export default ClientFeedbackVG;
