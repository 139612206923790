import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import Slider from 'react-slick';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Head from 'next/head';
import useStyles from './styles';
import slick from './slick';
import slickTheme from './slick-theme';

const NextArrow = ({ onClick, className }) => {
  const classes = useStyles();
  return (
    <Button className={clsx(className, classes.arrow, classes.arrow2)} onClick={onClick}>
      <ChevronRightIcon />
    </Button>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};
NextArrow.defaultProps = {
  onClick: () => {},
  className: ''
};

const PrevArrow = ({ onClick, className }) => {
  const classes = useStyles();
  return (
    <Button className={clsx(className, classes.arrow, classes.arrow1)} onClick={onClick}>
      <ChevronLeftIcon />
    </Button>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};
PrevArrow.defaultProps = {
  onClick: () => {},
  className: ''
};

const Carousel = React.forwardRef(
  ({ children, nextArrowSettings, prevArrowSettings, initialSlide, ...props }, ref) => {
    const classes = useStyles(props);
    const ownRef = React.useRef(null);
    const carouselRef = ref ?? ownRef;

    // Update carousel position when data changed as the initial slide
    // prop only works initially, but then the next and previous methods
    // work as if the first slide is always 0.
    React.useEffect(() => {
      carouselRef.current.slickGoTo(initialSlide);
    }, [initialSlide]);

    return (
      <Box className={classes.carouselRoot}>
        <style id="hello" type="text/css" dangerouslySetInnerHTML={{ __html: slick }} />
        <style id="hello1" type="text/css" dangerouslySetInnerHTML={{ __html: slickTheme }} />
        <Slider
          nextArrow={<NextArrow {...nextArrowSettings} />}
          prevArrow={<PrevArrow {...prevArrowSettings} />}
          {...props}
          initialSlide={initialSlide}
          ref={carouselRef}
        >
          {children}
        </Slider>
      </Box>
    );
  }
);

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  slidesToShow: PropTypes.number,
  nextArrowSettings: PropTypes.objectOf(PropTypes.any),
  prevArrowSettings: PropTypes.objectOf(PropTypes.any),
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToScroll: PropTypes.number,
  responsive: PropTypes.instanceOf(Array),
  initialSlide: PropTypes.number,
  variableWidth: PropTypes.bool,
  centerMode: PropTypes.bool,
  lazyLoad: PropTypes.bool
};

Carousel.defaultProps = {
  children: null,
  slidesToShow: 3,
  nextArrowSettings: {},
  prevArrowSettings: {},
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  responsive: [],
  initialSlide: 0,
  variableWidth: false,
  centerMode: false,
  lazyLoad: false
};

export default Carousel;
